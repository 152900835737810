<template>
  <b-form @submit.stop.prevent>
    <br />
    <b-form-group>
      <base-select
        v-model="statementName"
        :options="statementNameOptions"
        @setSelected="changeStatement()"
      ></base-select>
    </b-form-group>
    <statement-description @openOrgDetails="selectStatementOrgDetails"></statement-description>
    <keep-alive exclude="StatementDocument">
      <component
        :is="isComponent"
        :document="document"
        :buttonText="buttonText"
        :currentStatement="currentStatement"
      ></component>
    </keep-alive>
  </b-form>
</template>

<script>
import BaseSelect from '@/components/shared/inputs/BaseSelect.vue';
import StatementDescription from '@/components/statements/StatementDescription.vue';
import { sendCustomerRequest } from '@/api/common';
import URL from '@/basUrl';
import { catchFunction, ShowErrorToUser } from '@/additional_helpers/helpers';
import { ShowCurrentDate } from '@/additional_helpers/dates';
import { mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    statementNameOptions: {
      type: Array,
      require: true
    },
    listOfStatements: {
      type: Array,
      require: true
    }
  },

  provide() {
    const createDocument = {
      createDocument: this.createDocument,
      createRequestBody: this.createAndSaveRequestBody,
      isIcon: false,
      isFooterBtnLeft: false,
      buttonText: this.buttonText
    };
    return createDocument;
  },

  data() {
    return {
      isLoading: true,
      isSpinner: true,
      statementName: '',
      document: '',
      buttonText: 'Сформировать документ',
      validErrorMessage: 'Заполните все обязательные поля для оформления обращения'
    };
  },

  components: {
    BaseSelect,
    StatementDescription,
    AddOrRemoveAni: () => import('@/components/statements/statements_form/StatementAddOrRemoveAni.vue'),
    AddServices: () => import('@/components/statements/statements_form/StatementAddServices.vue'),
    DisableServices: () => import('@/components/statements/statements_form/StatementDisableServices.vue'),
    EnableService: () => import('@/components/statements/statements_form/StatementEnableService.vue'),
    StatementForwarding: () => import('@/components/statements/statements_form/StatementForwarding.vue'),
    OrgDetails: () => import('@/components/statements/statements_form/StatementOrgDetails.vue'),
    StatementInvite: () => import('@/components/statements/statements_form/StatementInvite.vue'),
    LetterOfIndemnity: () => import('@/components/statements/statements_form/StatementLetterOfIndemnity.vue'),
    StatementRefund: () => import('@/components/statements/statements_form/StatementRefund.vue'),
    StatementRelocation: () => import('@/components/statements/statements_form/StatementRelocation.vue'),
    SuspensionOfContract: () => import('@/components/statements/statements_form/StatementSuspensionOfContract.vue'),
    StatementTermination: () => import('@/components/statements/statements_form/StatementTermination.vue'),
    StatementDocument: () => import('@/components/statements/StatementDocument.vue')
  },

  created() {
    // Проверяет был ли переход осуществлен со страницы реквизитов
    if (!this.$route.query.st) this.statementName = this.statementNameOptions[8].value;
    if (this.$route.query.st === 'orgDetails') this.statementName = 'ChangeCustomerInfoStatement';
    if (this.$route.query.st === 'addService') this.statementName = 'AddServicesStatement';
  },

  beforeUpdate() {
    this.setSendValue();
  },

  beforeDestroy() {
    // Скрывает сформированный документ
    this.MAKE_ACTIVE(false);
  },

  computed: {
    ...mapGetters({ isActive: 'isActive' }),

    // Вычисляет какую форму заявки показать
    isComponent() {
      // eslint-disable-next-line max-len
      const checkStatement = (typeOfStatement) => this.statementName === typeOfStatement && !this.isActive;

      if (checkStatement('AddOrRemoveAniStatement')) return 'AddOrRemoveAni';
      if (checkStatement('AddServicesStatement')) return 'AddServices';
      if (checkStatement('DisableServiceStatement')) return 'DisableServices';
      if (checkStatement('EnableServiceStatement')) return 'EnableService';
      if (checkStatement('ChangeOrSetForwardingStatement')) return 'StatementForwarding';
      if (checkStatement('ChangeCustomerInfoStatement')) return 'OrgDetails';
      if (checkStatement('InviteBonusStatement')) return 'StatementInvite';
      if (checkStatement('LetterOfIndemnityStatement')) return 'LetterOfIndemnity';
      if (checkStatement('RefundStatement')) return 'StatementRefund';
      if (checkStatement('MoveServiceLocationStatement')) return 'StatementRelocation';
      if (checkStatement('SuspensionOfContractStatement')) return 'SuspensionOfContract';
      if (this.isActive) return 'StatementDocument';
      return 'StatementTermination';
    },

    // Выделяет название на русском и имя ключа активной формы заявки
    currentStatement() {
      const [currentTypeOptions] = this.statementNameOptions.filter(
        (el) => el.value === this.statementName
      );
      const result = this.listOfStatements.find((el) => el.Name === currentTypeOptions.value);
      return result;
    }
  },

  methods: {
    ...mapMutations({
      MAKE_ACTIVE: 'MAKE_ACTIVE',
      SET_DATA: 'SET_DATA',
      SHOW_LOADING: 'SHOW_LOADING'
    }),

    // Отправляет запрос на создание печатной формы документа и отображения его в лк
    createDocument(sendingData) {
      // При не успешной валидации
      if (!this.validate(sendingData)) {
        ShowErrorToUser(this.validErrorMessage);
        return;
      }

      // При успешной валидации
      const row = this.createAndSaveRequestBody(sendingData);

      sendCustomerRequest(URL.STATEMENTS_LAYOUT, row)
        .then((result) => {
          if (result.status !== 200) {
            catchFunction(result.text);
            return;
          }
          this.document = result.text;
          this.MAKE_ACTIVE(true);
        })
        .catch((e) => catchFunction(e));
    },

    // Создает объект для отправки на сервер
    createAndSaveRequestBody(newData) {
      const requestBody = JSON.stringify({
        StatementInfo: this.currentStatement,
        Model: {
          ...newData,
          DateOfStatement: `${ShowCurrentDate()}T00:00:00`
        }
      });

      this.SET_DATA(requestBody);
      return requestBody;
    },

    // Отмечает нужно ли отправлять заявку онлайн
    setSendValue() {
      if (this.currentStatement?.CanSend) sessionStorage.setItem('canSend', this.currentStatement.CanSend);
    },

    async changeStatement() {
      // При переходе со страницы реквизитов меняет адрес и меняет адрес с query на новый без query
      if (this.$route.query.st) {
        await this.$router.replace({
          name: 'TheStatementCreation'
        });
        this.SHOW_LOADING(false);
      }
      // Меняет тип заявки для отображения, если показан уже сформированный документ
      this.MAKE_ACTIVE(false);
      this.setSendValue();
    },

    validate(arr) {
      const isValid = Object.values(arr);
      return !isValid.some((el) => {
        if (typeof el === 'object') return Object.values(el).some((item) => !item);
        return !el;
      });
    },

    async selectStatementOrgDetails() {
      await this.$router.replace({
        name: 'TheStatementCreation',
        query: { st: 'orgDetails' }
      });
      this.statementName = 'ChangeCustomerInfoStatement';
      this.SHOW_LOADING(false);
    }
  }
};
</script>

<style></style>
