<template>
  <the-wrapper :isSpinner="isSpinner" :isFullWidth="isFullWidth">
    <slot>
      <h2>Создать заявку</h2>
      <StatementsCreation
        :statementNameOptions="statementNameOptions"
        :listOfStatements="listOfStatements"
      />
    </slot>
  </the-wrapper>
</template>

<script>
import TheWrapper from '@/components/shared/TheWrapper.vue';
import StatementsCreation from '@/components/statements/StatementsCreation.vue';
import { getServiceData } from '@/api/common';
import URL from '@/basUrl';
import { catchFunction } from '@/additional_helpers/helpers';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      isSpinner: true,
      isFullWidth: true,
      statementNameOptions: [],
      listOfStatements: []
    };
  },

  created() {
    // Получает список форм
    Promise.all([getServiceData(URL.STATEMENTS_LIST), getServiceData(URL.STATEMENT_SERVICES)])
      .then((result) => {
        const [statementsList, statementsServices] = result;
        this.listOfStatements = statementsList;
        this.statementNameOptions = this.createListOfStatements(statementsList);
        this.sortStatements(this.statementNameOptions);
        this.ADD_SERVICES(statementsServices);
      })
      .then(() => {
        this.getCompanyAndCustomerData({ vm: this });
      })
      .catch((e) => catchFunction(e));
  },

  updated() {
    this.SHOW_LOADING(false);
  },

  components: {
    StatementsCreation,
    TheWrapper
  },

  computed: mapGetters({ isLoading: 'isLoading' }),

  methods: {
    ...mapActions({ getCompanyAndCustomerData: 'statements/getCompanyAndCustomerData' }),
    ...mapMutations({
      ADD_SERVICES: 'statements/ADD_SERVICES',
      SHOW_LOADING: 'SHOW_LOADING'
    }),

    // Создаёт список доступных форм заявок для отображения в select
    createListOfStatements(obj) {
      return obj.map((el) => ({
        value: el.Name,
        text: el.Description,
        CanSend: el.CanSend
      }));
    },

    sortStatements(list) {
      list.sort((curr, prev) => (curr.text > prev.text ? 1 : -1));
    }
  }
};
</script>

<style></style>
