<template>
  <p>
    * Совет: если автоматически подставленные реквизиты не верны -
    <b @click="showOrgDetailsStatement" :class="$style.advice">сообщите нам </b>
  </p>
</template>

<script>
export default {
  methods: {
    showOrgDetailsStatement() {
      this.$emit('openOrgDetails');
    }
  }
};
</script>

<style module>
.advice {
  cursor: pointer;
}
</style>
